<template>
  <section class="full-height">
    <div class="columns is-marginless">
      <div class="column is-12">    
          <div class="columns is-gapless is-marginless is-centered is-mobile">
            <div class="column is-narrow is-mobile">
              <div class="has-text-grey is-size-3 is-size-4-mobile has-text-weight-light wa-underlined" >Manage Server</div>
            </div>
          </div>
      </div>
    </div>
    
    <div class="is-mobile is-gapless is-centered is-multiline m2rem">
      <b-table
          :data="serverData"
          :bordered="true"
          :striped="true"
          :narrowed="true"
          :loading="tableIsLoading"
          :mobile-cards="true">

          <b-table-column field="servers_id" label="id" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
              <a :href='`${WEB_ROOT}/Servermanage/${props.row.servers_sec_key}/${props.row.servers_name}`' target="_blank">{{ props.row.servers_id }}</a>
          </b-table-column>
          <b-table-column field="servers_name" label="name" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
              <a :href='`${WEB_ROOT}/Servermanage/${props.row.servers_sec_key}/${props.row.servers_name}`' target="_blank">{{ props.row.servers_name }}</a>
          </b-table-column>

          <b-table-column field="servers_updated_at" label="updated_at" width="100" :td-attrs="columnTdAttrs" v-slot="props" sortable searchable>
            <div class="highlight">
              {{ props.row.servermanage_updated_at }}
            </div>
          </b-table-column>
          
          <b-table-column field="LOCKED" label="Allow Changes" width="100" :td-attrs="columnTdAttrs" v-slot="props" sortable searchable>
            <b-field>
              <b-switch v-model="props.row.isActive" @input="setServerActiveState(props.row)">
                  Allow Changes
              </b-switch>
          </b-field>
          </b-table-column>
          

          <template #empty>
              <div class="has-text-centered">No records</div>
          </template>

      </b-table>
    </div>
    
       
    
    <b-notification :closable="false">
      <b-loading :is-full-page="true" :active.sync="pageLoading"></b-loading>
    </b-notification>
  </section>
</template>

<script>

// import { Auth }             from '@/plugins/firebase/auth';
// import { DB }               from '@/plugins/firebase/db';
// import axios_clean          from 'axios';
import axios_api from '@/plugins/axios_api';
// import { API_ROOT } from '@/config/app.js';

// import { Calc_TimeLeftMsg }         from '@/helpers/functions';
import { API_ROOT, WEB_ROOT } from '@/config/app.js';
// import { mapState } from 'vuex';

export default {
  name: "EmptyPage", 
  
         
  data() {
    return {
      pageLoading         : false,
      pageLoadingTimeout  : null,
      
      serverData          : [],
      tableIsLoading      : false,
      WEB_ROOT: WEB_ROOT,
      API_ROOT: API_ROOT,
      
     
    }
  },
  
  async mounted(){
    this.ShowPageLoading(15000)
    this.getData()
  },
  
  methods: {
    async getData() {
      let response = await axios_api.get(`${API_ROOT}/servermanage/admin/info`, {});
      if (response.data.result !== 1) {
        this.$buefy.dialog.alert({ title: 'Error', message: 'unable to get company data', type: 'is-danger' })
        this.HidePageLoading()
        return;
      }
      this.serverData  = response.data.payload.serversInfo
      for(let server of this.serverData) {
        server.isActive = server.servers_isActive === 1
      }
            
      console.info(`this.serverData:`, this.serverData)      
      this.HidePageLoading()
    },
    
    async setServerActiveState(server) {
      console.info(`setServerActiveState:`, server)
      console.info(`setServerActiveState:`, server.isActive)
      
      this.ShowPageLoading(15000)
      let response = await axios_api.post(`${API_ROOT}/servermanage/admin/setActiveState/${server.servers_name}/${server.servers_sec_key}/${server.isActive ? 1 :0 }`, {});
      if (response.data.result !== 1) {
        this.$buefy.dialog.alert({ title: 'Error', message: 'unable to set server state', type: 'is-danger' })
        this.HidePageLoading()
        return;
      }
      this.getData()      
    },
    
    /* eslint-disable-next-line */        
    dateThAttrs(column) {
      return null
    },
            
    /* eslint-disable-next-line */
    columnTdAttrs(row, column) {
      return null
    },
    
    async ShowPageLoading(maxDuration = 30000){
      this.pageLoading = true;
      
      if (this.pageLoadingTimeout !== null) {
        clearTimeout(this.pageLoadingTimeout);
      }
      
      this.pageLoadingTimeout = setTimeout(() => {
        this.HidePageLoading();
      }, maxDuration);
    },
    
    async HidePageLoading() {
      this.pageLoading = false;
      if (this.pageLoadingTimeout !== null) {
        clearTimeout(this.pageLoadingTimeout);
      }
    },
    
    
  },
};
</script>

<style>
    

</style>